import { FC, useState, useEffect, ChangeEvent } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "../components/Logout";
import ExitReportCard from "../components/ExitReportCard";
// import JobSatReportCard from "../components/JobSatReportCard";
import { getProtectedResource, updateResource } from "../services/apiRequest.service";
import { InstitutionListResponse, ApplicableYear, PermissionsData } from "../models/responseData";
import PageLoader from "../components/PageLoader";

const determineResponseTypeInstitutionList = (response: any): response is InstitutionListResponse[] => true;


const determineResponseTypePermissions = (response: any): response is PermissionsData => true;

interface HomeProps {
  getApplicableYears: () => Promise<void>;
  applicableYearsList: ApplicableYear[] | null;
}

const Home: FC<HomeProps> = ({ getApplicableYears, applicableYearsList }) => {
  const {
    isAuthenticated,
    user,
    getAccessTokenSilently,
    getIdTokenClaims
  } = useAuth0();
  const [username, setUsername] = useState<string | undefined>("");
  const [isCoacheAdmin, setIsCoacheAdmin] = useState(false);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [permissions, setPermissions] = useState<string[] | undefined>();

  const [institution, setInstitution] = useState({
    "institution_id": "",
    "institution_name": ""
  });
  const [homeInstitution, setHomeInstitution] = useState({
    "home_institution_id": "",
    "home_institution_name": ""
  });
  const [institutionList, setInstitutionList] = useState<InstitutionListResponse[] | []>([]);
  const [institutionListLoading, setInstitutionListLoading] = useState(true);

  const getMetadata = async () => {
    try {
      const claims = await getIdTokenClaims();
      const permissionsPromiseResolved = await getPermissions();
      if (claims) {
        // console.log('claims: ', claims)
        if (claims["coache/roles"][0] === "COACHE Admin") {
          setIsCoacheAdmin(true);
          getInstitutions();
        } else if (claims["coache/roles"][0] === "System Admin" && claims["allowed_institutions"]?.length > 0) {
          setIsSystemAdmin(true);
          getInstitutions();
        } else if (permissionsPromiseResolved?.includes("update:institution")) {
          getInstitutions();
          setHomeInstitution({
            ...homeInstitution,
            "home_institution_id": claims.home_institution_id,
            "home_institution_name": claims.home_institution_name
          });
        } else {
          setInstitution({
            ...institution,
            "institution_id": claims.institution_id,
            "institution_name": claims.institution_name
          });
          getApplicableYears();
          // console.log("institution_id:", claims.institution_id,
          // "institution_name:", claims.institution_name)
        }

      }
    } catch (error) {
      console.error("Error:", error);
    }
  }


  const getPermissions = async () => {
    try {
      const accessToken = await getAccessTokenSilently({ cacheMode: "off" });
      const requestUrl = `admin/permissions`;
      const { data } = await getProtectedResource(accessToken, requestUrl);
      if (data && determineResponseTypePermissions(data)) {
        setPermissions(data.permissions);
        return data.permissions;
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getInstitutions = async () => {
    try {
      const accessToken = await getAccessTokenSilently({ cacheMode: "off" });
      const requestUrl = `admin/list_institutions`;
      const { data } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeInstitutionList(data)) {
        setInstitutionList(data.sort((a: any, b: any) => a.institution.localeCompare(b.institution)));
        setInstitutionListLoading(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      setUsername(user?.name);
      getMetadata();
    }
  }, [])

  const changeInstitutions = async (e: ChangeEvent<HTMLSelectElement>) => {
    try {
      const accessToken = await getAccessTokenSilently();

      const updatedData = {
        "institution_id": isNaN(parseInt(e.target.value)) ? e.target.value : Number(e.target.value)
      }

      const requestUrl = `admin/change_institution`;
      const responseStatus = await updateResource(accessToken, requestUrl, updatedData);

      if (responseStatus === 200) {
        console.log("Changed institutions successfully.")
        const foundInstitution = institutionList.find((institution) => {
          const value = e.target.value;
          if (isNaN(parseInt(value))) {
            // if the value isn't a number, compare it as a string
            return institution.reportid === value;
          } else {
            // if the value is parseable as a number, cast it to a number and compare
            return institution.reportid === Number(value);
          }
        });
        if (foundInstitution && foundInstitution.institution) {
          setInstitution({
            ...institution,
            "institution_id": e.target.value,
            "institution_name": foundInstitution.institution.toString(),
          });
        }
        await getApplicableYears();
      }
    } catch (error) {
      console.error("Error in changing institutions:", error);
    }
  }

  return (
    <div className="home">
      <div className="banner">
        <div className="username">{username}</div>
        <LogoutButton />
      </div>

      <h1>COACHE&rsquo;s Reporting Page</h1>


      {(isCoacheAdmin || isSystemAdmin || permissions?.includes("update:institution")) &&
        <>
        {/* Display a message if the user has the update:institution permission but is missing a home institution in the metadata */}
          {!isCoacheAdmin && !isSystemAdmin && permissions?.includes("update:institution") && (!homeInstitution.home_institution_id || !homeInstitution.home_institution_name) ?
            <div>
              User has system report permissions but is missing a home institition assignment. Please contact <a id="email-link" href="mailto: coache_exit@gse.harvard.edu">coache_exit@gse.harvard.edu</a> for assistance.
            </div> :
            <>
              <div>In what institution are you interested?</div>
              {institutionListLoading ? <div>Loading institutions...</div> : (
                <div>
                  <select
                    onChange={(e) => {
                      if (e.target.value.toString() !== institution.institution_id.toString()) {
                        changeInstitutions(e);
                      }
                    }}
                  >
                    <option hidden={true}>Select an institution</option>
                    <option disabled defaultValue={""}>Select an institution</option>
                    {institutionList.map((institution, i) => (
                      <option
                        value={institution.reportid}
                        key={i}
                      >
                        {institution.institution}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </>
          }
        </>
      }

      {institution.institution_name !== "" &&
        <div>
          <div>
            {(applicableYearsList && applicableYearsList.length > 0) &&
              <>
                <h2>{institution.institution_name}&rsquo;s Exit and Retention Reports</h2>
                <div className="report-cards">
                  {applicableYearsList.map((applicableYear) =>
                    <ExitReportCard
                      key={applicableYear.year}
                      applicableYear={applicableYear}
                      institution={institution}
                    />
                  )}
                </div>
              </>
            }
            {(applicableYearsList && applicableYearsList.length === 0) &&
              <div>
                User does not have the permissions set to view the available reports. Please contact <a id="email-link" href="mailto: coache_exit@gse.harvard.edu">coache_exit@gse.harvard.edu</a> for assistance.
              </div>
            }
            {applicableYearsList === null && <PageLoader />}
          </div>
          {/* placeholder for Job Satisfaction reports */}
          {/* <div>
            <h2>{institution.institution_name}&rsquo;s Job Satisfaction Reports</h2>
            <div className="report-cards">
              {applicableYearsList ?
                applicableYearsList.map((yearObject) =>
                  <JobSatReportCard
                    key={yearObject.year}
                    year={yearObject.year.toString()}
                  />
                )
                : <PageLoader />
              }
            </div>
          </div> */}
        </div>
      }
    </div>
  )
}

export default Home;